@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SourceHanSansCN';
  src: url('https://cdnwukong.com/public/fonts/SourceHanSansCN-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SourceHanSansCN';
  src: url('https://cdnwukong.com/public/fonts/SourceHanSansCN-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SourceHanSansCN';
  src: url('https://cdnwukong.com/public/fonts/SourceHanSansCN-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SourceMaruSC';
  src:
    url('https://cdnwukong.com/fonts/SourceMaruSC-Bold.woff2') format('woff2'),
    url('https://cdnwukong.com/fonts/SourceMaruSC-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SourceMaruSC';
  src:
    url('https://cdnwukong.com/fonts/SourceMaruSC-Medium.woff2') format('woff2'),
    url('https://cdnwukong.com/fonts/SourceMaruSC-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SourceMaruSC';
  src:
    url('https://cdnwukong.com/fonts/SourceMaruSC-Regular.woff2') format('woff2'),
    url('https://cdnwukong.com/fonts/SourceMaruSC-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT';
  src: url('/fonts/Poppins-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('/fonts/Figtree-Light.ttf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('/fonts/Figtree-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('/fonts/Figtree-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('/fonts/Figtree-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('/fonts/Figtree-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('/fonts/Figtree-ExtraBold.ttf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('/fonts/Figtree-Black.ttf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
//
//@font-face {
//  font-family: 'MiSans';
//  src: url('/fonts/MiSans/woff2/MiSans-Thin.woff2') format('woff2'),
//  url('/fonts/MiSans/woff2/MiSans-Thin.woff') format('woff');
//  font-weight: 150;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'MiSans';
//  src: url('/fonts/MiSans/woff2/MiSans-ExtraLight.woff2') format('woff2'),
//  url('/fonts/MiSans/woff2/MiSans-ExtraLight.woff') format('woff');
//  font-weight: 200;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'MiSans';
//  src: url('/fonts/MiSans/woff2/MiSans-Light.woff2') format('woff2'),
//  url('/fonts/MiSans/woff2/MiSans-Light.woff') format('woff');
//  font-weight: 250;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'MiSans';
//  src: url('/fonts/MiSans/woff2/MiSans-Normal.woff2') format('woff2'),
//  url('/fonts/MiSans/woff2/MiSans-Normal.woff') format('woff');
//  font-weight: 305;
//  font-style: normal;
//}

@font-face {
  font-family: 'MiSans';
  src:
    url('/fonts/MiSans/woff2/MiSans-Regular.woff2') format('woff2'),
    url('/fonts/MiSans/woff2/MiSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MiSans';
  src:
    url('/fonts/MiSans/woff2/MiSans-Medium.woff2') format('woff2'),
    url('/fonts/MiSans/woff2/MiSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MiSans';
  src:
    url('/fonts/MiSans/woff2/MiSans-Demibold.woff2') format('woff2'),
    url('/fonts/MiSans/woff2/MiSans-Demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MiSans';
  src:
    url('/fonts/MiSans/woff2/MiSans-Semibold.woff2') format('woff2'),
    url('/fonts/MiSans/woff2/MiSans-Semibold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MiSans';
  src:
    url('/fonts/MiSans/woff2/MiSans-Bold.woff2') format('woff2'),
    url('/fonts/MiSans/woff2/MiSans-Bold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'MiSans';
  src:
    url('/fonts/MiSans/woff2/MiSans-Heavy.woff2') format('woff2'),
    url('/fonts/MiSans/woff2/MiSans-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
