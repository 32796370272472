@import '~@/styles/config.scss';
@import '~@/styles/viewport.scss';
@import '~@/styles/font.scss';
@import '~@/styles/tailwind.scss';

* {
  font-family: inherit;
}
// body {
//   font-family: 'Roboto','SourceHanSansCN';
// }

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
    scrollbar-width: thin;
  }
}
.rc-pagination-item-active {
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  color: #333;
  margin-right: 14px;
  &:hover {
    border: none;
  }
}
.JA-Dpu {
  z-index: 100 !important;
}
.rc-pagination-item {
  border: none;
  div {
    box-shadow: 0px 2.4px 4.8px rgba(210, 217, 222, 0.17);
  }
  &:focus,
  &:hover {
    transition: border 0.3s;
  }
}

.rc-pagination-disabled {
  opacity: 0.4;
}
#select {
  .select__menu {
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.09);
    border-radius: 16px;
    padding: 10px;
    z-index: 100;
  }
  .select__menu-list {
    z-index: 100;
    height: 200px;
  }
  .select__option {
    font-size: 16px;
    padding: 10px 12px;
    width: 280px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 4px;
    border-radius: 10px;
    &--is-focused {
      background: #f9f9f9;
      cursor: pointer;
    }
    &--is-selected {
      background: #f9f9f9;
      color: #333;
      font-weight: bold;
    }
    &:first-child {
      margin-top: 0px;
    }
  }
}
.cookie {
  position: fixed;
  bottom: 20px;
  width: 1100px;
  left: 50%;
  transform: translateX(-50%);
  min-height: 70px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 8px #eee;
  z-index: 9;
  display: flex;
  align-items: center;
  padding: 0 18px 0 30px;
  box-sizing: border-box;
  justify-content: space-between;
  &-tip {
    font-size: 14px;
    a {
      color: #ff5353;
    }
  }
  &-operate {
    display: flex;
    width: 300px;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  &-disable,
  &-enable {
    display: flex;
    min-width: 90px;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 15px;
    margin-right: 20px;
    cursor: pointer;
  }
  &-disable {
    color: #ff5353;
    border: 1px solid #eee;
  }
  &-enable {
    background-color: #ff5353;
    color: #fff;
  }
}
@media (max-width: 500px) {
  .cookie {
    height: 28vh;
    width: 100vw;
    bottom: 0;
    flex-direction: column;
    padding: vw(20);
    justify-content: flex-start;
    &-tip {
      font-size: vw(14);
    }
    &-disable,
    &-enable {
      display: flex;
      padding: 3vw 6vw;
      border-radius: 8px;
      font-size: 15px;
      margin-right: 6vw;
      cursor: pointer;
      font-size: vw(16);
    }
    &-enable {
      margin-right: 0;
    }
    &-operate {
      margin-top: vw(20);
      svg {
        position: fixed;
        right: vw(10);
        bottom: vw(154);
        width: vw(24);
      }
    }
  }
}
.intercom {
  .intercom-lightweight-app,
  .intercom-launcher {
    display: none;
  }
  iframe::before {
    content: '.intercom-launcher';
    display: none;
  }
}
 /* 隐藏滚动条 */
 .hideScrollbar::-webkit-scrollbar {
  display: none; /* 完全隐藏滚动条 */
}

.hideScrollbar {
  -ms-overflow-style: none; /* 对IE和Edge的兼容 */
  scrollbar-width: none; /* 在Firefox中隐藏滚动条 */
}