@import '@/styles/viewport';
@import '@/styles/mediaQuery';
@import '../styles/mixins.scss';

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0a0a0ab2;
  backdrop-filter: blur(1px);
  z-index: 101;
}

html:lang(zh) .modal * {
  font-family: 'MiSans', 'Figtree', 'PingFang SC', sans-serif !important;
  -webkit-tap-highlight-color: transparent;
}

html:lang(en) .modal * {
  font-family: 'Figtree', 'MiSans', 'PingFang SC', sans-serif !important;
  -webkit-tap-highlight-color: transparent;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background: #fff;
  z-index: 102;
  box-sizing: border-box;
  color: #1b1f4d;
  text-align: center;
  user-select: none;

  * {
    box-sizing: border-box;
  }

  @include mediaS {
    width: vw(290);
    padding: vw(16);
    border-radius: vw(12);
  }

  @include mediaL {
    max-width: 468px;
    width: fit-content;
    padding: 24px;
    border-radius: 12px;
  }

  .closeIcon {
    position: absolute;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    > img {
      -webkit-tap-highlight-color: transparent;
    }

    @include mediaS {
      display: none;
      width: vw(20);
      height: vw(20);
      right: vw(10);
      top: vw(10);
    }

    @include mediaL {
      width: 24px;
      height: 24px;
      right: 8px;
      top: 8px;
    }
  }

  .title {
    font-style: normal;
    font-weight: 500;
    white-space: pre-wrap;

    @include mediaS {
      @include fontSize(vw(16));
      line-height: vw(24);
    }

    @include mediaL {
      @include fontSize(16px);
      line-height: 24px;
    }
  }

  .content {
    color: #0a0a0a;
    @include mediaS {
      margin-top: vw(12);
      @include fontSize(vw(16));
      line-height: vw(24);
    }

    @include mediaL {
      margin-top: 12px;
      @include fontSize(16px);
      line-height: 24px;
    }
  }

  .promptInputBox {
    width: 100%;
    background: #f5f5fc;
    position: relative;

    @include mediaS {
      margin-top: vw(12);
      border-radius: vw(6);
      height: vw(40);
    }

    @include mediaL {
      margin-top: 12px;
      border-radius: 6px;
      height: 44px;
    }

    .promptInput {
      flex: 1;
      height: 100%;
      width: 100%;
      background: transparent;
      outline: none;
      border: none;
      color: #1b1f4d;
      text-align: center;

      @include mediaS {
        @include fontSize(vw(14));
      }
    }

    .inputClearIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      @include mediaS {
        width: vw(16);
        height: vw(16);
        right: vw(12);
      }

      @include mediaL {
        width: 16px;
        height: 16px;
        right: 12px;
      }
    }
  }

  .footer {
    display: grid;
    align-items: center;
    justify-content: space-between;

    @include mediaS {
      grid-template-columns: 1fr;
      margin-top: vw(24);
      gap: vw(15);
      place-items: center;
    }

    @include mediaL {
      margin-top: 24px;
      grid-template-columns: 1fr 1fr;
      place-items: center;
      gap: 24px;
    }

    &.singleButton {
      .button {
        width: 100%;
      }

      @include mediaS {
        grid-template-columns: 1fr;
      }

      @include mediaL {
        grid-template-columns: 1fr;
      }

      .confirmButton {
        @include mediaL {
          width: auto;
          min-width: 164px;
          padding: 0 32px;
        }
      }
    }

    .cancelButton {
      @include mediaS {
        color: #999999;
        grid-row: 2;
        border: none;
        width: fit-content;

        &::before {
          display: none;
        }
      }

      @include mediaL {
        grid-row: 1;
      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      background: transparent;
      font-weight: 600;
      cursor: pointer;
      border: none;
      -webkit-tap-highlight-color: transparent;

      @include mediaS {
        height: vw(40);
        border-radius: vw(24);
        @include fontSize(vw(14));
      }

      @include mediaL {
        height: 44px;
        border-radius: 24px;
      }

      &.cancelButtonStyle2 {
        color: #484c70;

        @include mediaS {
          border: vw(1) solid #cccdd9;
        }
      }

      &.confirmButton {
        color: #fff;
        // background: linear-gradient(90deg, #3B0087 0%, #1D267F 100%);
        background-color: #ff5353;
        border: none;

        @include mediaL {
          width: 100%;
        }

        &.disabled {
          color: rgba(255, 255, 255, 0.5);
          // background: linear-gradient(90deg, #44009A 0%, #1D267F 100%), #FFF;
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

.modal.confirm {
  .footer {
    @include mediaL {
      grid-template-columns: 1fr;
    }
  }
}

.modal-enter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.modal-enter-active {
  transition: transform 300ms;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
}

.modal-exit {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
}

.modal-exit-active {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 300ms;
}
