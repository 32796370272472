// #kaamelAlertBig.kaamelAlertBigBanner {
//   &.kaamelAlertBigClosed {
//     display: none !important;
//   }
//   display: flex !important;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
//   overflow: visible !important;
//   @media (min-width: 1440px) {
//     max-width: 1160px !important;
//   }
//   @media (min-width: 1024px) and (max-width: 1440px) {
//     max-width: 850px !important;
//   }
//   #cookieIcon {
//     display: none;
//   }
//   #newCloseRight {
//     top: -14px;
//     right: -14px;
//     border-radius: 14px;
//     background: #ffffff;
//     width: 28px;
//     height: 28px;
//     padding-top: 0 !important;
//     border: 1px solid #0000000f;
//     svg {
//       width: 20px;
//       height: 20px;
//       g {
//         opacity: 1;
//       }
//       path {
//         fill: #0a0a0a !important;
//         stroke: #0a0a0a;
//       }
//     }
//   }
//   #mainTextContent {
//     text-align: left !important;
//     color: $fc !important;
//     margin-top: 0 !important;
//     margin-bottom: 0 !important;
//     margin-left: 0 !important;
//   }
// }

// // Mobile
// #kaamelAlertBig.kaamelAlertBigMobile.kaamelAlertBigBanner {
//   height: unset !important;
//   padding-bottom: 16px !important;
//   padding-top: 0 !important;
//   display: initial !important;
//   &.kaamelAlertBigClosed {
//     display: none !important;
//   }
//   #mainBarKaamelAllAllowedSetting,
//   #mainBarKaamelAllDeniedSetting,
//   #kaamelPanelConfirm {
//     padding: 9.5px 20px !important;
//     border-radius: 999px !important;
//     color: #ffffff !important;
//     line-height: 16.8px !important;
//     background-color: #cd292a !important;
//     margin-left: 16px !important;
//   }
//   #mainTextContent {
//     margin-top: 0 !important;
//     margin-bottom: 16px !important;
//     margin-right: 0 !important;
//     padding: 16px 16px 0 16px;
//   }

//   #kaamelPersonalizeSettingRight {
//     margin-top: 12px;
//     padding-left: 16px;
//     justify-content: flex-start;
//   }

//   #mainBtnDiv {
//     display: block !important;
//   }
// }

// #kaamelAlertBig.kaamelAlertBigMobile.kaamelAlertBigExpanded {
//   height: unset !important;
//   #panelFooter {
//     position: relative !important;
//     width: 100% !important;
//   }
//   .cookieDetailWrapRight {
//     height: 292px !important;
//     overflow: auto !important;
//   }
//   #kaamelPanelConfirmDiv {
//     margin-bottom: 0 !important;
//   }
//   #topArea:after {
//     font-weight: 800 !important;
//     margin-top: 0 !important;
//   }
//   #mainTextContent {
//     margin-top: 12px !important;
//   }
//   .notshow + #cookieDetailWrapRightDiv #cookieDetailWrapRight {
//     height: unset !important;
//   }
//   .serviceInfoHead {
//     color: $fc04 !important;
//   }
//   .serviceInfo {
//     color: $fc !important;
//   }
//   .selectedInfo {
//     border-color: $fc03 !important;
//     #dropdownIcon path {
//       fill: $fc03 !important;
//     }
//   }
//   .optionSelectedIcon svg path {
//     fill: $fc03 !important;
//   }
//   .dropdown-content span {
//     color: $fc;
//   }
// }

// body #kaamelRoot div#kaamelAlertBig.kaamelAlertBigExpanded {
//   &.kaamelAlertBigClosed {
//     display: none !important;
//   }
//   left: calc(max(20px, 50% - 280px)) !important;
//   // top: calc((100% - 87% - 48px) / 2) !important;
//   #panelFooter {
//     bottom: calc((100% - 87% - 48px) / 2) !important;
//     #newPowerdBy {
//       display: flex;
//       align-items: center;
//     }
//   }
//   display: block;
//   overflow: visible !important;
//   #topArea:after {
//     text-align: left;
//     content: 'Customize Settings';
//     color: #1a223f;
//     font-size: 18px;
//     margin-top: 20px;
//   }
//   #cookieIcon {
//     display: none;
//   }
//   #newCloseRight {
//     top: -14px;
//     right: -14px;
//     border-radius: 14px;
//     background: #ffffff;
//     width: 28px;
//     height: 28px;
//     padding-top: 0 !important;
//     svg {
//       width: 20px;
//       height: 20px;
//       path {
//         fill: #000000 !important;
//         stroke: #000000;
//       }
//     }
//   }
//   #mainTextContent {
//     margin-bottom: 16px !important;
//   }
//   #mainTextContent,
//   #topArea:after,
//   .accordionServiceRight,
//   .kaamelCookieDetailInfo1,
//   .kaamelCookieDetailInfo {
//     color: $fc !important;
//   }
//   .categorySwitchBtn:not(.notshow) {
//     color: $fc !important;
//   }
//   .categorySwitchBtn:has(.notshow) {
//     color: $fc02 !important;
//   }
//   .btnBottomLineShort {
//     background-color: $fc03 !important;
//     border-radius: 99px;
//   }
//   .btnBottomLine {
//     background-color: rgba(0, 0, 0, 0.06) !important;
//   }

//   #mainBarKaamelAllAllowedSetting,
//   #mainBarKaamelAllDeniedSetting,
//   #kaamelPanelConfirm {
//     padding: 9.5px 20px !important;
//     border-radius: 999px !important;
//     color: #ffffff !important;
//     line-height: 16.8px !important;
//     background-color: #cd292a !important;
//     margin-left: 16px !important;
//     &:hover {
//       background-color: #db0202 !important;
//     }
//   }
//   #mainBtnDiv {
//     margin-bottom: 20px !important;
//   }

//   .cookieDetailWrapRight {
//     border: 2px solid rgba(0, 0, 0, 0.06) !important;
//   }

//   .alwaysActiveStatusInfoDiv {
//     background-color: #cd292a14 !important;
//     padding: 3.5px 6px !important;
//     border-radius: 6px;
//     .alwaysActiveStatusInfoRight {
//       color: $fc03 !important;
//     }
//   }
//   .expansionButtonDiv {
//     background-color: #cd292a14 !important;
//     width: 40px !important;
//     height: 40px !important;
//   }
//   #expansionButton svg {
//     width: 24px;
//     height: 24px;
//     path {
//       fill: #cd292a !important;
//     }
//   }

//   .servicesListIconRight {
//     margin-top: 12px !important;
//   }

//   .accordionServiceWrapRight:hover {
//     border-color: #cd292a !important;
//     .servicesListIconRight svg {
//       path {
//         fill: #cd292a !important;
//       }
//     }
//   }

//   .infoDetailReturnBtn:hover {
//     border-color: #cd292a !important;
//   }

//   #infoDetailReturnBtn {
//     svg path {
//       fill: #cd292a !important;
//     }
//   }
// }

// div#kaamelAlertBig.kaamelAlertBigPC {
//   #cookieSettingDetail {
//     height: unset !important;
//   }

//   #cookieDetailWrapRight {
//     height: 438px !important;
//   }
// }

// body #kaamelRoot div#kaamelAlertBig.kaamelAlertBigPC.kaamelAlertBigExpanded {
//   height: unset !important;
//   #panelFooter {
//     position: relative !important;
//     width: 100% !important;
//     bottom: unset !important;
//   }
// }

// #kaamelPersonalizeSettingRightLink {
//   text-decoration: none !important;
//   border: 1px solid #0000000f !important;
//   padding: 9.5px 20px !important;
//   border-radius: 999px;
//   color: $fc !important;
//   line-height: 16.8px !important;
//   &:hover {
//     background-color: #0a0a0a !important;
//     color: #fff !important;
//   }
// }

// #kaamelAlertBig .kaamelAlertBigBanner #mainBtnDiv #mainBarKaamelAllDeniedSetting,
// #mainBarKaamelAllAllowedSetting {
//   margin-bottom: 0 !important;
// }

// #kaamelAlertBig.kaamelAlertBigPC.kaamelAlertBigBanner #mainBarKaamelAllDeniedSetting {
//   border: 1px solid #0000000f !important;
//   padding: 9.5px 20px !important;
//   border-radius: 999px !important;
//   color: #0a0a0a !important;
//   line-height: 16.8px !important;
//   background-color: #fff !important;
//   &:hover {
//     background-color: #0a0a0a !important;
//     color: #fff !important;
//   }
// }

// #kaamelAlertBig.kaamelAlertBigPC.kaamelAlertBigBanner #mainBarKaamelAllAllowedSetting {
//   padding: 9.5px 20px !important;
//   border-radius: 999px !important;
//   color: #ffffff !important;
//   line-height: 16.8px !important;
//   background-color: #cd292a !important;
//   margin-left: 16px !important;
//   &:hover {
//     background-color: #db0202 !important;
//   }
// }

// body #kaamelRoot #kaamelAlertBig.kaamelAlertBigPC.kaamelAlertBigBanner {
//   left: 50% !important;
//   transform: translateX(-50%) !important;
//   padding: 24px 24px !important;

// }
// body #kaamelRoot #kaamelAlertBig.kaamelAlertBigPC.kaamelAlertBigExpanded {
//   padding: 0 !important;
//   #topArea:after {
//     font-weight: 800;
//     margin-top: 24px;
//   }
//   #mainTextContent {
//     margin-top: 12px !important;
//   }
//   .serviceInfoHead {
//     color: $fc04 !important;
//   }
//   .serviceInfo {
//     color: $fc !important;
//   }
// }
// #kaamelRoot .newPowerdBy span {
//   color: #1b1f4d !important;
//   font-size: 14px !important;
// }

// #kaamelAlertBig.kaamelAlertBigCCPA.kaamelAlertBigMobile.kaamelAlertBigBanner {
//   #kaamelPersonalizeSettingRight {
//     margin-bottom: 12px !important;
//   }
// }

// ==== 优化一下SCSS
$color1: #0a0a0a;
$color2: #8d8fa6;
$color3: #cd292a;
$color4: #999999;
$color5: #db0202;

#kaamelRoot {
  // 图标
  #cookieIcon {
    display: none;
  }

  // 关闭icon
  // prettier-ignore
  #newCloseRight {
    top: -14PX;
    right: -14PX;
    border-radius: 14PX;
    background: #ffffff;
    width: 28PX;
    height: 28PX;
    padding-top: 0;
    border: 1px solid #0000000f;
    svg {
      width: 20PX;
      g {
        opacity: 1;
      }
      path {
        fill: $color1;
        stroke: $color1;
      }
    }
  }

  // 1440
  @media (min-width: 1440px) {
    .kaamelAlertBigCCPA.kaamelAlertBigBanner {
      #mainBtnDiv {
        margin-top: 0 !important;
      }
    }
    .kaamelAlertBigPC.kaamelAlertBigBanner {
      display: flex !important;
      align-items: center;
      flex-direction: row;

      max-width: 1160px !important;
      padding: 24px 24px !important;

      #mainBtnDiv {
        position: absolute;
        right: 10px;
        // customize button
        #kaamelPersonalizeSettingRightLink {
          text-decoration: none !important;
          border: 1px solid #0000000f !important;
          padding: 9.5px 20px !important;
          border-radius: 999px;
          color: $color1 !important;
          line-height: 16.8px !important;
          &:hover {
            background-color: $color1 !important;
            color: #fff !important;
          }
        }
        #mainBarKaamelAllAllowedSetting {
          padding: 9.5px 20px !important;
          border-radius: 999px !important;
          color: #ffffff !important;
          line-height: 16.8px !important;
          background-color: $color3 !important;
          margin-left: 16px !important;
          &:hover {
            background-color: $color5 !important;
          }
        }
      }
    }
    .kaamelAlertBigGDPR.kaamelAlertBigBanner {
      #mainTextContent {
        width: calc(100% - 420px) !important;
      }
      #mainBtnDiv {
        margin-top: 0;
      }
      &.kaamelAlertBigLang-en {
        #mainBtnDiv {
          right: 190px !important;
        }
        #kaamelPersonalizeSettingRight {
          position: absolute;
          right: 24px;
        }
      }
      &.kaamelAlertBigLang-zh {
        #mainBtnDiv {
          right: 140px !important;
        }
        #kaamelPersonalizeSettingRight {
          position: absolute;
          right: 24px;
        }
      }
    }
  }

  // 1024 - 1440
  @media (min-width: 1024px) and (max-width: 1440px) {
    .kaamelAlertBigCCPA.kaamelAlertBigBanner {
      #mainTextContent {
        width: calc(100% - 300px) !important;
      }
      #mainBtnDiv {
        margin-top: 0 !important;
      }
    }
    .kaamelAlertBigGDPR.kaamelAlertBigBanner {
      #mainTextContent {
        width: calc(100% - 420px) !important;
      }
      #mainBtnDiv {
        margin-top: 0;
      }
      &.kaamelAlertBigLang-en {
        #mainBtnDiv {
          right: 190px !important;
        }
        #kaamelPersonalizeSettingRight {
          position: absolute;
          right: 24px;
        }
      }
      &.kaamelAlertBigLang-zh {
        #mainBtnDiv {
          right: 140px !important;
        }
        #kaamelPersonalizeSettingRight {
          position: absolute;
          right: 24px;
        }
      }
    }
    .kaamelAlertBigPC.kaamelAlertBigBanner {
      display: flex !important;
      align-items: center;
      flex-direction: row;

      max-width: 850px !important;
      padding: 24px 24px !important;

      #mainBtnDiv {
        position: absolute;
        right: 10px;
        #mainBarKaamelAllAllowedSetting {
          padding: 9.5px 20px !important;
          border-radius: 999px !important;
          color: #ffffff !important;
          line-height: 16.8px !important;
          background-color: $color3 !important;
          margin-left: 16px !important;
          &:hover {
            background-color: $color5 !important;
          }
        }
      }
    }
  }

  // 375 - 1024
  @media (min-width: 375px) and (max-width: 1024px) {
    .kaamelAlertBigPC.kaamelAlertBigBanner {
      max-width: 480px !important;
      background: rgba(255, 255, 255, 1) !important;
      border-width: 1px !important;
      border: 0px solid rgba(255, 83, 83, 1) !important;
      bottom: 20px;
      top: auto !important;
      left: 20px;
      left: 20px !important;
      transform: initial !important;
      border-radius: 12px !important;
      width: Calc(100% - 46px) !important;
      min-width: 0 !important;
      // prettier-ignore
      padding: 16PX !important;
      #mainBarKaamelAllDeniedSetting {
        height: unset !important;
      }
      #mainTextContent {
        text-align: left !important;
      }
    }
    .kaamelAlertBigGDPR.kaamelAlertBigPC.kaamelAlertBigBanner {
      // prettier-ignore
      #mainBtnDiv {
        margin-top: 16PX;
        margin-right: 0;
      }
      // prettier-ignore
      #kaamelPersonalizeSettingRight {
        margin-top: 16PX;
      }

      #mainTextContent {
        // text-align: center !important;
        margin-right: 0 !important;
      }
    }
  }
  
  .kaamelAlertBigMobile {
    display: initial !important;
  }

  #kaamelAlertBig {
    // 区分GDPR和CCPA

    // 公用样式
    &.kaamelAlertBigBanner,
    &.kaamelAlertBigExpanded {
      overflow: visible !important;
    }

    // prettier-ignore
    #kaamelPersonalizeSettingRightLink {
      text-decoration: none !important;
      border: 1px solid #0000000f !important;
      padding: 9.5PX 20PX !important;
      border-radius: 999px;
      color: $color1 !important;
      line-height: 16.8PX !important;
      &:hover {
        background-color: $color1 !important;
        color: #fff !important;
      }
    }

    #mainBtnDiv {
      // prettier-ignore
      margin-top: 16PX;

      // prettier-ignore
      #mainBarKaamelAllAllowedSetting {
        padding: 9.5PX 20PX !important;
        border-radius: 999px !important;
        color: #ffffff !important;
        line-height: 16.8PX !important;
        background-color: $color3 !important;
        margin-left: 16PX !important;
        &:hover {
          background-color: $color5 !important;
        }
      }
      // prettier-ignore
      #mainBarKaamelAllDeniedSetting {
        border: 1px solid #0000000f !important;
        padding: 9.5PX 20PX !important;
        border-radius: 999px !important;
        color: $color1 !important;
        line-height: 16.8PX !important;
        background-color: #fff !important;
        &:hover {
          background-color: $color1 !important;
          color: #fff !important;
        }
      }
    }

    // PC
    &.kaamelAlertBigPC {
      &.kaamelAlertBigBanner {
        left: 50% !important;
        transform: translateX(-50%) !important;
        #mainTextContent {
          text-align: left !important;
          color: $color1 !important;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          margin-left: 0 !important;
        }
      }
      &.kaamelAlertBigExpanded {
        height: unset !important;
        padding: 0 !important;

        #panelFooter {
          position: relative !important;
          width: 100% !important;
          bottom: unset !important;
        }
        #topArea:after {
          font-weight: 800;
          margin-top: 24px;
        }
        #mainTextContent {
          margin-top: 12px !important;
        }
        .serviceInfoHead {
          color: $color4 !important;
        }
        .serviceInfo {
          color: $color1 !important;
        }
      }
    }
    // 闭合类
    .kaamelAlertBigBanner {
    }

    // 展开类
    &.kaamelAlertBigExpanded {
      left: calc(max(20px, 50% - 280px)) !important;
      // top: calc((100% - 87% - 48px) / 2) !important;
      #panelFooter {
        bottom: calc((100% - 87% - 48px) / 2) !important;
        #newPowerdBy {
          display: flex;
          align-items: center;
        }
      }
      display: block;
      overflow: visible !important;
      #topArea:after {
        text-align: left;
        content: var(--kaamel-cookie-title);
        color: #1a223f;
        font-size: 18px;
      }
      #cookieIcon {
        display: none;
      }
      #newCloseRight {
        top: -14px;
        right: -14px;
        border-radius: 14px;
        background: #ffffff;
        width: 28px;
        height: 28px;
        padding-top: 0 !important;
        svg {
          width: 20px;
          height: 20px;
          path {
            fill: #000000 !important;
            stroke: #000000;
          }
        }
      }
      #mainTextContent {
        margin-bottom: 16px !important;
      }
      #mainTextContent,
      #topArea:after,
      .accordionServiceRight,
      .kaamelCookieDetailInfo1,
      .kaamelCookieDetailInfo {
        color: $color1 !important;
      }
      .categorySwitchBtn:not(.notshow) {
        color: $color1 !important;
      }
      .categorySwitchBtn:has(.notshow) {
        color: $color2 !important;
      }
      .btnBottomLineShort {
        background-color: $color3 !important;
        border-radius: 99px;
      }
      .btnBottomLine {
        background-color: rgba(0, 0, 0, 0.06) !important;
      }

      // prettier-ignore
      #mainBarKaamelAllAllowedSetting,
      #mainBarKaamelAllDeniedSetting,
      #kaamelPanelConfirm {
        padding: 9.5PX 20PX !important;
        border-radius: 999px !important;
        color: #ffffff !important;
        line-height: 16.8PX !important;
        background-color: $color3 !important;
        margin-left: 16PX !important;
        &:hover {
          background-color: $color5 !important;
        }
      }
      #mainBtnDiv {
        margin-bottom: 20px !important;
      }

      .cookieDetailWrapRight {
        border: 2px solid rgba(0, 0, 0, 0.06) !important;
      }

      .alwaysActiveStatusInfoDiv {
        background-color: #cd292a14 !important;
        padding: 3.5px 6px !important;
        border-radius: 6px;
        .alwaysActiveStatusInfoRight {
          color: $color3 !important;
        }
      }
      .expansionButtonDiv {
        background-color: #cd292a14 !important;
        width: 40px !important;
        height: 40px !important;
      }
      #expansionButton svg {
        width: 24px;
        height: 24px;
        path {
          fill: $color3 !important;
        }
      }

      .servicesListIconRight {
        margin-top: 12px !important;
      }

      .accordionServiceWrapRight:hover {
        border-color: $color3 !important;
        .servicesListIconRight svg {
          path {
            fill: $color3 !important;
          }
        }
      }

      .infoDetailReturnBtn:hover {
        border-color: $color3 !important;
      }

      #infoDetailReturnBtn {
        svg path {
          fill: $color3 !important;
        }
      }
    }

    // CCPA
    &.kaamelAlertBigCCPA {
      &.kaamelAlertBigMobile {
        #kaamelPersonalizeSettingRight {
          margin-top: 0;
        }
        #mainTextContent {
          margin-left: 0 !important;
        }
      }
    }

    // GDPR
    &.kaamelAlertBigGDPR {
      #mainBtnDiv {
        margin-top: 0;
      }
      &.kaamelAlertBigMobile.kaamelAlertBigBanner {
        #mainTextContent {
          margin-left: 0 !important;
        }
        #mainBtnDiv {
          justify-content: flex-start;
          margin-left: 16px;

          #mainBarKaamelAllDeniedSetting {
            margin-left: 0 !important;
          }
        }
      }
    }

    // PC-banner

    // PC-expand

    // MB-banner
    &.kaamelAlertBigMobile.kaamelAlertBigClosed,
    &.kaamelAlertBigPC.kaamelAlertBigClosed {
      display: none !important;
    }

    &.kaamelAlertBigMobile {
      height: unset !important;
      padding-bottom: 16px !important;
      #mainTextContent {
        margin-top: 0 !important;
        margin-bottom: 16px !important;
        margin-right: 0 !important;
        padding: 0 16px 0 16px;
      }

      &.kaamelAlertBigBanner {
        #mainTextContent {
          text-align: left !important;
        }
      }

      #kaamelPersonalizeSettingRight {
        margin-top: 12px;
        padding-left: 16px;
        justify-content: flex-start;
      }

      &.kaamelAlertBigExpanded {
        height: unset !important;
        #panelFooter {
          position: relative !important;
          width: 100% !important;
        }
        .cookieDetailWrapRight {
          height: 292px !important;
          overflow: auto !important;
        }
        #kaamelPanelConfirmDiv {
          margin-bottom: 0 !important;
        }
        #topArea:after {
          font-weight: 800 !important;
          margin-top: 0 !important;
        }
        #mainTextContent {
          margin-top: 12px !important;
        }
        .notshow + #cookieDetailWrapRightDiv #cookieDetailWrapRight {
          height: unset !important;
        }
        .serviceInfoHead {
          color: $color4 !important;
        }
        .serviceInfo {
          color: $color1 !important;
        }
        .selectedInfo {
          border-color: $color3 !important;
          #dropdownIcon path {
            fill: $color3 !important;
          }
        }
        .optionSelectedIcon svg path {
          fill: $color3 !important;
        }
        .dropdown-content span {
          color: $color1;
        }
      }
    }

    // MB-expand
  }
}
