@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .hide-scrollbar::-webkit-scrollbar {
     display: none;
  }

  .forbid-select-bg {
    -webkit-tap-highlight-color: transparent;
  }

  .common-border {
    border: 1px solid rgba(0, 0, 0, 0.06);
  }

  .common-box-shadow {
    box-shadow: 0px 12px 20px 0px rgba(26, 26, 26, 0.08);
  }

  .overflow-wrap-anywhere {
    overflow-wrap: anywhere;
  }
}