@use 'sass:math';



@function custom-round($number) {
    $step1: math.div(round($number * 10), 10); // 第一步：保留一位小数
    $step2: round($step1); // 第二步：再四舍五入到整数
    @return $step2;
}


// 定义两个不同的类
@mixin fontSize($number) {

    // 检查 html 的 lang 属性是否为 zh
    html:lang(zh) & {
        // 如果是 zh，则返回 $number 除以 1.1
        font-size: custom-round(math.div($number, 1.1));
    }

    // 否则，直接返回 $number
    html:not(:lang(zh)) & {
        font-size: $number;
    }
}