@import '@/styles/viewport';
@import '@/styles/mediaQuery';
@import '../styles/mixins.scss';

.button {
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 600;
  box-sizing: border-box;
  border-radius: 999px;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  @include mediaS {
    @include fontSize(vw(16));
    height: vw(48);
  }

  @include mediaL {
    @include fontSize(16px);
    height: 52px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.default {
    background: #fff;
    color: #0a0a0a;
    position: relative;

    &.disabled {
      border: none;
      background: #f5f5f5;
      color: #d4d4d4;
    }

    @include mediaS {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: vw(3) solid rgba(0, 0, 0, 0.06);
        border-radius: vw(999);
        width: 200%;
        height: 200%;
        transform: scale(0.5);
        transform-origin: 0 0;
        box-sizing: border-box;
      }
    }

    @include mediaL {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 3px solid rgba(0, 0, 0, 0.06);
        border-radius: 999px;
        width: 200%;
        height: 200%;
        transform: scale(0.5);
        transform-origin: 0 0;
        box-sizing: border-box;
      }
    }
  }

  &.primary {
    background: #cd292a;
    color: #fff;

    &.disabled {
      background: linear-gradient(0deg, rgba(205, 41, 42, 0.2) 0%, rgba(205, 41, 42, 0.2) 100%), #fff;
    }
  }
}
